import React, {
  type FC,
  memo,
  useState,
  useEffect,
} from "@messenger/lib/teact/teact";

import { getActions } from "@messenger/global";
import { Api } from "@messenger/lib/gramjs";

import BusinessAuthHeader from "./BusinessAuthHeader";
import { cn } from "@messenger/shared/styles";
import AvatarEdit from "@messenger/components/businessAuth/AvatarEdit";
import { uploadFile } from "@messenger/api/gramjs/methods/client";
import useI18n from "@messenger/hooks/useI18n";
import { isUsernameValid } from "@messenger/util/username";

type OwnerForm = Pick<
  Api.account.CreateBusinessAccount,
  "firstName" | "lastName" | "email" | "username" | "photo"
> & {
  password: string;
  confirmPassword: string;
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const CreateAccount: FC = () => {
  const { dictionary } = useI18n(["business"]);
  const { setBusinessAuthState, createBusinessAccount } = getActions();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState("");

  const [emailError, setEmailError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | undefined>();

  const [form, setForm] = useState<OwnerForm>({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    photo: undefined,
  });

  useEffect(() => {
    (async () => {
      if (selectedFile) {
        const file = await uploadFile(selectedFile);
        setForm((current) => ({ ...current, photo: file }));
      } else {
        setForm((current) => ({ ...current, photo: undefined }));
      }
    })();
  }, []);

  const validatePassword = () => {
    const { password, confirmPassword } = form;
    if (password !== confirmPassword) {
      setPasswordError(dictionary.business.errors.password);
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  const validateUsername = (value: string) => {
    if (value.length === 0) {
      setUsernameError(dictionary.business.errors.emptyUsername);
      return false;
    } else if (!isUsernameValid(value, true)) {
      setUsernameError(dictionary.business.errors.username);
      return false;
    } else {
      setUsernameError("");
      return true;
    }
  };

  const validateFirstName = (value: string) => {
    if (value.length === 0) {
      setFirstNameError(dictionary.business.errors.firstName);
      return false;
    } else {
      setFirstNameError("");
      return true;
    }
  };

  const validateEmail = (value: string) => {
    if (!emailRegex.test(value)) {
      setEmailError(dictionary.business.errors.email);
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const handleFormChange = (value: string, id: keyof OwnerForm) => {
    if (id === "email") {
      validateEmail(value);
    }

    if (id === "firstName") {
      validateFirstName(value);
    }

    if (id === "username") {
      validateUsername(value);
    }

    setForm({
      ...form,
      [id]: value,
    });
  };

  const handleSubmit = () => {
    if (emailError || firstNameError || usernameError) return;
    if (
      validateUsername(form.username ?? "") &&
      validateFirstName(form.firstName ?? "") &&
      validateEmail(form.email ?? "") &&
      validatePassword()
    ) {
      createBusinessAccount({ form });
    }
  };

  return (
    <div className="flex flex-col items-center w-full h-full">
      <BusinessAuthHeader title={dictionary.business.signIn.title} />
      <div className="flex flex-col gap-2 w-full max-w-sm h-full">
        <div className="w-full flex justify-center items-center"></div>
        <input
          className="h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]"
          placeholder={dictionary.business.signIn.userName}
          value={form["username"]}
          onChange={(e) => handleFormChange(e.target.value, "username")}
        />
        {usernameError && (
          <span className="text-red-500 text-left text-xs pl-4">
            {usernameError}
          </span>
        )}

        <input
          className="h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]"
          placeholder={dictionary.business.signIn.firstName}
          value={form["firstName"]}
          onChange={(e) => handleFormChange(e.target.value, "firstName")}
        />
        {firstNameError && (
          <span className="text-red-500 text-left text-xs pl-4">
            {firstNameError}
          </span>
        )}
        <input
          className="h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]"
          placeholder={dictionary.business.signIn.lastName}
          value={form["lastName"]}
          onChange={(e) => handleFormChange(e.target.value, "lastName")}
        />
        <input
          className="h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]"
          placeholder={dictionary.business.signIn.email}
          value={form["email"]}
          onChange={(e) => handleFormChange(e.target.value, "email")}
        />
        {emailError && (
          <span className="text-red-500 text-left text-xs pl-4">
            {emailError}
          </span>
        )}
        <div className="relative w-full">
          <input
            className="h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]"
            placeholder={dictionary.business.signIn.password}
            value={form["password"]}
            onChange={(e) => handleFormChange(e.target.value, "password")}
            type={passwordVisible ? "text" : "password"}
          />
          <i
            onClick={() => setPasswordVisible(!passwordVisible)}
            className={cn(
              "icon absolute right-3 top-3.5 text-[#37D937] cursor-pointer",
              !passwordVisible ? "icon-eye" : "icon-eye-closed",
            )}
          />
        </div>
        <input
          className="h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]"
          placeholder={dictionary.business.signIn.confirmPassword}
          value={form["confirmPassword"]}
          onChange={(e) => handleFormChange(e.target.value, "confirmPassword")}
          type={passwordVisible ? "text" : "password"}
        />
        {passwordError && (
          <span className="text-red-500 text-left text-xs pl-4">
            {passwordError}
          </span>
        )}
        <button
          onClick={handleSubmit}
          className="mt-auto h-11 w-full max-w-sm bg-black rounded-xl text-[#37CB37] cursor-pointer"
        >
          {dictionary.business.signIn.button}
        </button>
      </div>
    </div>
  );
};

export default memo(CreateAccount);
