import React, { type FC, memo } from "@messenger/lib/teact/teact";
import useI18n from "@@/src/app/hooks/useI18n";

type BusinessAuthHeaderProps = {
  title: string;
  withSubtitle?: boolean;
};

const BusinessAuthHeader: FC<BusinessAuthHeaderProps> = ({
  title,
  withSubtitle = true,
}) => {
  const { dictionary } = useI18n(["business"]);

  return (
    <div className="mb-4">
      <h2 className="text-black text-center text-2xl font-semibold leading-normal tracking-wide">
        {title}
      </h2>
      {withSubtitle && (
        <div className="text-gray-500 text-center text-base font-normal leading-normal tracking-wide">
          {dictionary.business.signIn.header.text}{" "}
          <a
            className="text-green-500"
            target="_blank"
            href="https://tawasal.ae/privacy_policy.html"
          >
            {dictionary.business.signIn.header.privacy}
          </a>{" "}
          {dictionary.business.signIn.header.and}{" "}
          <a
            className="text-green-500"
            target="_blank"
            href="https://tawasal.ae/terms-of-use"
          >
            {dictionary.business.signIn.header.terms}
          </a>
        </div>
      )}
    </div>
  );
};

export default memo(BusinessAuthHeader);
