import bigInt from "big-integer";
import {
    generateRandomBytes,
    modExp,
    readBigIntFromBuffer,
    readBufferFromBigInt,
    sha1,
} from "@messenger/lib/gramjs/Helpers";

const PUBLIC_KEYS = [
    {
        fingerprint: bigInt("-642835645902056311"),
        n: bigInt(
            // "ae3c65507628d4df7df1b4b7ea4c9d743f7636efd194cfd6320987fa7149be0fc9f37f1c17b0068409f61b6efdbeea888c23130c3f88102828cbdc962c8bcfb6bb8790106791e5a9242b4b7b3d305986d92bf034fea6a852a66cabd4939bb8d9ffe8e11a9c8110842bcfc356aadb4e0b4f4ec8c42d03a4d90be4e3f76ef2afc9dcec98c1fe004a10b783763538fbe86e2c78be94bb9542311e11d2ed09149f3c5e51481e0d93a0b61da3845451a6d5e6971f4344e69b105c9a5b048dee5964924443297dc88a646b86f8c1e8b7f9567884a0d99678b4a127bc1facb244e24e3113d27b340f1272c3ff5f27cd245088c872a42c4b467376cdb688c8cc3a1d9711",
            "21995247322855102974532046738906918229761245611799535659277384599944579596629125642976150406122096397020034290301435461171948332618108917006277012590550651567036872175711823308668632448750235478880259911558513145137142635688667345440595254159372718050313022329512472894374787500350268296186139848210492388669894528472945926019441945934440383900915552383090907325240827298479321373631806519821497818789002475660900499875392975979925129103398241633694940625193463060116585326747854712449331370654387598054124044395570878475681490420345141413934781883096220774738422069364813296637504510313502095678046783293761271994129",
        ),
        e: 65537,
    },
    // {
    //     fingerprint: bigInt('-5595554452916591101'),
    //     n: bigInt(
    //         '2534288944884041556497168959071347320689884775908477905258202659454602246385394058588521595116849196570'
    //         + '8222649399180603818074200620463776135424884632162512403163793083921641631564740959529419359595852941166'
    //         + '8489405859523376133330223960965841179548922160312292373029437018775884567383353986024616752250817918203'
    //         + '9315375750495263623495132323782003654358104782690612092797248736680529211579223142368426126233039432475'
    //         + '0785450942589751755390156647751460719351439969059949569615302809050721500330239005077889855323917509948'
    //         + '255722081644689442127297605422579707142646660768825302832201908302295573257427896031830742328565032949',
    //     ),
    //     e: 65537,
    // },
];
export const _serverKeys = new Map<
    string,
    { n: bigInt.BigInteger; e: number }
>();

PUBLIC_KEYS.forEach(({ fingerprint, ...keyInfo }) => {
    _serverKeys.set(fingerprint.toString(), keyInfo);
});
// .reduce((acc, { fingerprint, ...keyInfo }) => {
//     acc.set(fingerprint.toString(), keyInfo);
//     return acc;
// }, new Map<string, { n: bigInt.BigInteger; e: number }>());

/**
 * Encrypts the given data known the fingerprint to be used
 * in the way Telegram requires us to do so (sha1(data) + data + padding)

 * @param fingerprint the fingerprint of the RSA key.
 * @param data the data to be encrypted.
 * @returns {Buffer|*|undefined} the cipher text, or undefined if no key matching this fingerprint is found.
 */
export async function encrypt(fingerprint: bigInt.BigInteger, data: Buffer) {
    const key = _serverKeys.get(fingerprint.toString());
    if (!key) {
        return undefined;
    }

    // len(sha1.digest) is always 20, so we're left with 255 - 20 - x padding
    const rand = generateRandomBytes(235 - data.length);

    const toEncrypt = Buffer.concat([await sha1(data), data, rand]);

    // rsa module rsa.encrypt adds 11 bits for padding which we don't want
    // rsa module uses rsa.transform.bytes2int(to_encrypt), easier way:
    const payload = readBigIntFromBuffer(toEncrypt, false);
    const encrypted = modExp(payload, bigInt(key.e), key.n);
    // rsa module uses transform.int2bytes(encrypted, keylength), easier:
    return readBufferFromBigInt(encrypted, 256, false);
}
