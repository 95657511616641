import { useEffect, useState } from "@messenger/lib/teact/teact";

const defaultLanguage = "en";
const defaultNamespaces = ["business"]; // Default namespaces to load (can be extended)
import enBusiness from "../dicts/en/business.json";
import { getActions, getGlobal } from "@messenger/global";

import { type LangCode } from "@messenger/types";

type Dict = {
  business: typeof enBusiness;
};

class LanguageManager extends EventTarget {
  private language: LangCode;
  constructor() {
    super();
    this.language =
      (getGlobal().settings?.byKey?.language as LangCode) ||
      (localStorage.getItem("langCode") as LangCode) ||
      defaultLanguage;
  }

  setLanguage(newLang: LangCode) {
    this.language = newLang;
    const { setSettingOption } = getActions();
    localStorage.setItem("language", newLang);
    setSettingOption({ language: newLang });
    this.dispatchEvent(
      new CustomEvent("languageChange", { detail: { newLang } }),
    );
  }

  getLanguage() {
    return this.language;
  }
}

export let languageManager: LanguageManager = new LanguageManager();

const useI18n = (namespaces = defaultNamespaces) => {
  useEffect(() => {
    languageManager?.setLanguage(getGlobal().settings.byKey.language);
  }, []);

  const [dictionary, setDictionary] = useState<Dict>({ business: enBusiness });
  const [language, setLanguage] = useState(languageManager.getLanguage());
  const [loading, setLoading] = useState(true);

  const loadNamespaces = async (lang: LangCode) => {
    const loadedDictionaries = {} as Dict;

    try {
      for (const namespace of namespaces) {
        loadedDictionaries[namespace as "business"] = await import(
          `../dicts/${lang}/${namespace}.json`
        );
      }
      setDictionary(loadedDictionaries);
    } catch (error) {
      console.error(
        `Error loading ${lang} namespaces, falling back to English`,
        error,
      );
      const fallbackDictionaries = {} as Dict;
      for (const namespace of namespaces) {
        fallbackDictionaries[namespace as "business"] = await import(
          `../dicts/en/${namespace}.json`
        );
      }
      setDictionary(fallbackDictionaries);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Load the namespaces when the language changes
    loadNamespaces(language);

    // Listen for language changes using event listeners
    const handleLanguageChange = (event: { detail: { newLang: LangCode } }) => {
      setLanguage(event.detail.newLang);
      loadNamespaces(event.detail.newLang);
    };

    // @ts-ignore
    languageManager.addEventListener("languageChange", handleLanguageChange);

    // Cleanup the event listener on unmount
    return () => {
      languageManager.removeEventListener(
        "languageChange",
        // @ts-ignore
        handleLanguageChange,
      );
    };
  }, [language, namespaces.toString()]);

  return {
    dictionary,
    loading,
    language,
    setLanguage: languageManager.setLanguage.bind(languageManager),
  };
};

export default useI18n;
