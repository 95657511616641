class EventBuilder {
    constructor(args = {
        chats: undefined,
        blacklistChats: undefined,
        func: undefined,
    }) {
        this.chats = args.chats;
        this.blacklistChats = Boolean(args.blacklistChats);
        this.resolved = false;
        this.func = args.func;
    }


    build(update) {

    }
}


module.exports = {
    EventBuilder,
};
