import {
  addActionHandler,
  getActions,
  getGlobal,
  setGlobal,
} from "@messenger/global";

import { Api } from "@messenger/lib/gramjs";

import { initApi, callApi } from "@messenger/api/gramjs";

import {
  LANG_CACHE_NAME,
  CUSTOM_BG_CACHE_NAME,
  MEDIA_CACHE_NAME,
  MEDIA_CACHE_NAME_AVATARS,
  MEDIA_PROGRESSIVE_CACHE_NAME,
  IS_TEST,
  LOCK_SCREEN_ANIMATION_DURATION_MS,
} from "@messenger/config";
import {
  IS_MOV_SUPPORTED,
  IS_WEBM_SUPPORTED,
  PLATFORM_ENV,
} from "@messenger/util/environment";
import { unsubscribe } from "@messenger/util/notifications";
import * as cacheApi from "@messenger/util/cacheApi";
import { updateAppBadge } from "@messenger/util/appBadge";
import {
  storeSession,
  loadStoredSession,
  clearStoredSession,
  importLegacySession,
  clearLegacySessions,
} from "@messenger/util/sessions";
import { forceWebsync } from "@messenger/util/websync";
import {
  clearGlobalForLockScreen,
  updatePasscodeSettings,
} from "@messenger/global/reducers";
import {
  clearEncryptedSession,
  encryptSession,
  forgetPasscode,
} from "@messenger/util/passcode";
import { serializeGlobal } from "@messenger/global/cache";
import {
  REGULAR_USER_LOCAL_KEY,
  MULTI_USERS_MAP,
} from "@messenger/global/helpers";

addActionHandler("initApi", async (global, actions) => {
  if (!IS_TEST) {
    await importLegacySession();
    void clearLegacySessions();
  }

  void initApi(actions.apiUpdate, {
    userAgent: navigator.userAgent,
    platform: PLATFORM_ENV,
    sessionData: loadStoredSession(),
    isTest: window.location.search.includes("test"),
    isMovSupported: IS_MOV_SUPPORTED,
    isWebmSupported: IS_WEBM_SUPPORTED,
  });
});

addActionHandler("initBusiness", async (global, actions, payload) => {
  // const result = await callApi("getBusinessAccount")
  const { companyInvite } = payload;
  if (companyInvite) {
    return {
      ...global,
      businessAuthState: "businessAuthStateSignUpEmployer",
    };
  } else {
    return {
      ...global,
      businessAuthState: "businessAuthStateSignIn",
    };
  }
  // let self = await callApi("getSelfUser");
  // if (!self) {
  //   window.location.assign(window.location.origin);
  //   return;
  // }
  // let businessAuthState: typeof global["businessAuthState"] = "businessAuthStateAccountInfo";
  // let ownerAuthForm: typeof global["ownerAuthForm"];
  // if (!self.business && !self.company) {
  //   const data = await callApi("importBusinessAuth")
  //   if (!data) {
  //     businessAuthState = "businessAuthStateAccountInfo";
  //   } else {
  //     self = await callApi("getSelfUser");
  //   }
  // }
  // if (self.business && !self.company) {
  //   businessAuthState = "businessAuthStateCompanyName";
  //   ownerAuthForm = {
  //     username: self?.username,
  //     firstName: self?.firstName,
  //     lastName: self?.lastName,
  //   }
  // }
  // if (self.business && self.company) {
  //   businessAuthState = "businessAuthStateReady"
  // }
  // return {
  //   ...global,
  //   businessAuthState,
  //   ownerAuthForm,
  // }
});

addActionHandler("setAuthPhoneNumber", (global, actions, payload) => {
  const { phoneNumber } = payload;

  void callApi("provideAuthPhoneNumber", phoneNumber.replace(/[^\d]/g, ""));
  // void callApi('provideAuthPhoneNumber', phoneNumber.replace(/[^+\d]/g, ''));

  return {
    ...global,
    authIsLoading: true,
    authError: undefined,
  };
});

addActionHandler("setAuthCode", (global, actions, payload) => {
  const { code } = payload;

  void callApi("provideAuthCode", code);

  return {
    ...global,
    authIsLoading: true,
    authError: undefined,
  };
});

addActionHandler("goBack", (global, actions, payload) => {
  const { state } = payload;

  return {
    ...global,
    authState: state,
    sendCodeResult: undefined,
  };
});

addActionHandler("resendCode", async (global, actions, payload) => {
  const { sendCodeResult } = global;

  const { phoneNumber } = payload;

  if (sendCodeResult) {
    const { phoneCodeHash } = sendCodeResult;
    const result = await callApi("resendCode", { phoneNumber, phoneCodeHash });
    if (result) {
      const finalResult = result as Api.auth.SentCode;
      return {
        ...global,
        sendCodeResult: {
          nextType: finalResult.nextType,
          phoneCodeHash: finalResult.phoneCodeHash,
          timeout: finalResult.timeout,
          isCodeViaApp: finalResult.type instanceof Api.auth.SentCodeTypeApp
        }
      }
    }
  }
});

addActionHandler("setAuthPassword", (global, actions, payload) => {
  const { password } = payload;

  void callApi("provideAuthPassword", password);

  return {
    ...global,
    authIsLoading: true,
    authError: undefined,
  };
});

addActionHandler("uploadProfilePhoto", (global, actions, payload) => {
  const { file } = payload;

  void callApi("uploadProfilePhoto", file);
});

addActionHandler("signUp", (global, actions, payload) => {
  const { firstName, lastName } = payload;

  void callApi("provideAuthRegistration", { firstName, lastName });

  return {
    ...global,
    authIsLoading: true,
    authError: undefined,
  };
});

addActionHandler("returnToAuthPhoneNumber", (global) => {
  void callApi("restartAuth");

  return {
    ...global,
    authError: undefined,
  };
});

addActionHandler("goToAuthQrCode", (global) => {
  void callApi("restartAuthWithQr");

  return {
    ...global,
    authIsLoadingQrCode: true,
    authError: undefined,
  };
});

addActionHandler("saveSession", (global, actions, payload) => {
  const { sessionData } = payload;

  if (sessionData) {
    storeSession(payload.sessionData, global.currentUserId);
  } else {
    clearStoredSession();
  }
});

addActionHandler("signOut", async () => {
  try {
    await unsubscribe();
    await callApi("destroy");
    //TODO: move to another location?
    localStorage.removeItem(REGULAR_USER_LOCAL_KEY);
    localStorage.removeItem(MULTI_USERS_MAP);
    await forceWebsync(false);
  } catch (err) {
    // Do nothing
  }

  getActions().reset();
});

addActionHandler("reset", () => {
  clearStoredSession();
  clearEncryptedSession();

  void cacheApi.clear(MEDIA_CACHE_NAME);
  void cacheApi.clear(MEDIA_CACHE_NAME_AVATARS);
  void cacheApi.clear(MEDIA_PROGRESSIVE_CACHE_NAME);
  void cacheApi.clear(CUSTOM_BG_CACHE_NAME);

  const langCachePrefix = LANG_CACHE_NAME.replace(/\d+$/, "");
  const langCacheVersion = Number.parseInt(
    <string>(LANG_CACHE_NAME.match(/\d+$/) || [0])[0],
  );

  for (let i = 0; i < langCacheVersion; i++) {
    void cacheApi.clear(`${langCachePrefix}${i === 0 ? "" : i}`);
  }

  void clearLegacySessions();

  updateAppBadge(0);

  getActions().init();
});

addActionHandler("softReset", () => {
  clearStoredSession();

  void clearLegacySessions();

  updateAppBadge(0);

  let global = getGlobal();
  global = clearGlobalForLockScreen(global);
  setGlobal(global);
});

addActionHandler("disconnect", () => {
  void callApi("disconnect");
});

addActionHandler("loadNearestCountry", async (global) => {
  if (global.connectionState !== "connectionStateReady") {
    return undefined;
  }

  const authNearestCountry = await callApi("fetchNearestCountry");

  return {
    ...getGlobal(),
    authNearestCountry,
  };
});

addActionHandler("setDeviceToken", (global, actions, deviceToken) => {
  return {
    ...global,
    push: {
      deviceToken,
      subscribedAt: Date.now(),
    },
  };
});

addActionHandler("deleteDeviceToken", (global) => {
  return {
    ...global,
    push: undefined,
  };
});

addActionHandler("lockScreen", async (global, { softReset }) => {
  const sessionJson = JSON.stringify({
    ...loadStoredSession(),
    userId: global.currentUserId,
  });
  const globalJson = serializeGlobal();

  await encryptSession(sessionJson, globalJson);
  forgetPasscode();

  global = getGlobal();

  setGlobal(
    updatePasscodeSettings(global, {
      isScreenLocked: true,
      invalidAttemptsCount: 0,
    }),
  );

  try {
    await unsubscribe();
    await callApi("destroy", true);
  } catch (err) {
    // Do nothing
  }

  setTimeout(() => softReset(), LOCK_SCREEN_ANIMATION_DURATION_MS);
});

addActionHandler("setSignUpEmployerFlow", async (global, actions, payload) => {
  return {
    ...global,
    isBusinessApp: true,
  };
});
