import { ChangeEvent } from "react";
import React, { type FC, memo, useState } from "@messenger/lib/teact/teact";
import { getInitials } from "./helpers/getInitials";

type AvatarEditProps = {
  form?: {
    firstName?: string;
    lastName?: string;
  };
  setSelectedFile: (avatar: File) => void;
  selectedFile?: File;
};
const AvatarEdit: FC<AvatarEditProps> = ({ form, setSelectedFile, selectedFile }) => {
  const [blobUrl, setBlobUrl] = useState<string | undefined>();

  const handleSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    if (!target || !target.files || !target.files[0]) {
      return;
    }

    setSelectedFile(target.files[0]);
    if (blobUrl) {
      URL.revokeObjectURL(blobUrl);
    }
    setBlobUrl(URL.createObjectURL(target.files[0]));
    target.value = "";
  };
  return (
    <div>
      <div className="h-20 w-20 rounded-full bg-gray-300 flex justify-center items-center text-white text-2xl mb-2 relative">
        {!blobUrl ? (
          getInitials(form?.firstName, form?.lastName)
        ) : (
          <img className="w-full h-full rounded-full" src={blobUrl} />
        )}
        {/* TODO: do we need delete?
        <i className="icon-delete text-black absolute bottom-0 right-0 text-sm cursor-pointer" /> */}
      </div>
      <label role="button" tabIndex={0}>
        <input
          type="file"
          onChange={handleSelectFile}
          accept="image/png, image/jpeg"
          className="hidden"
        />
        <span className="text-[#37CB37] text-base">Set photo</span>
      </label>
    </div>
  );
};

export default memo(AvatarEdit);
