import React, {
  type FC,
  memo,
  useEffect,
  useState,
  useRef,
} from "@messenger/lib/teact/teact";

import BusinessAuthHeader from "./BusinessAuthHeader";
import useI18n from "@messenger/hooks/useI18n";
import { getActions, withGlobal } from "@messenger/global";
import type { GlobalState } from "@messenger/global/types";
import AvatarEditable from "@messenger/components/ui/AvatarEditable";

type StateProps = Pick<
  GlobalState,
  "connectionState" | "authState" | "authQrCode" | "authError"
>;

type OwnerForm = {
  firstName: string;
  lastName: string;
};
const Register: FC<StateProps> = ({ authError }) => {
  const { dictionary } = useI18n(["business"]);

  const [form, setForm] = useState<OwnerForm>({
    firstName: "",
    lastName: "",
  });
  const [croppedFile, setCroppedFile] = useState<File | undefined>();

  const [firstNameError, setFirstNameError] = useState("");

  const { signUp, uploadProfilePhoto, clearAuthError } = getActions();

  const validateFirstName = (value: string) => {
    if (value.length === 0) {
      setFirstNameError(dictionary.business.errors.firstName);
      return false;
    } else {
      setFirstNameError("");
      return true;
    }
  };

  const handleFormChange = (value: string, id: keyof OwnerForm) => {
    if (authError) {
      clearAuthError();
    }
    if (id === "firstName") {
      validateFirstName(value);
    }
    setForm({
      ...form,
      [id]: value,
    });
  };

  const handleSubmit = async () => {
    const { firstName, lastName } = form;
    signUp({ firstName, lastName });
    if (croppedFile) {
      uploadProfilePhoto({ file: croppedFile });
    }
  };

  return (
    <div className="flex flex-col items-center w-full h-full">
      <BusinessAuthHeader title={dictionary.business.signIn.welcomeTawasal} />
      <div className="flex flex-col gap-2 w-full max-w-sm h-full">
        <AvatarEditable onChange={setCroppedFile} />
        <input
          className="h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]"
          placeholder={dictionary.business.signIn.firstName}
          value={form["firstName"]}
          onChange={(e) => handleFormChange(e.target.value, "firstName")}
        />
        {(firstNameError || authError) && (
          <span className="text-red-500 text-left text-xs pl-4">
            {firstNameError || authError}
          </span>
        )}
        <input
          className="h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]"
          placeholder={dictionary.business.signIn.lastName}
          value={form["lastName"]}
          onChange={(e) => handleFormChange(e.target.value, "lastName")}
        />
        <button
          onClick={handleSubmit}
          className="mt-auto h-11 w-full max-w-sm bg-black rounded-xl text-[#37CB37] cursor-pointer"
        >
          {dictionary.business.signIn.button}
        </button>
      </div>
    </div>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const {
      connectionState,
      authState,
      authQrCode,
      settings: {
        byKey: { language },
      },
      authError,
    } = global;

    return {
      connectionState,
      authState,
      authQrCode,
      authError,
    };
  })(Register),
);
