import {
  TelegramClient,
  sessions,
  Api as GramJs,
  connection,
} from "@messenger/lib/gramjs";
import { Logger as GramJsLogger } from "@messenger/lib/gramjs/extensions/index";
import type { TwoFaParams } from "@messenger/lib/gramjs/client/2fa";

import type {
  ApiInitialArgs,
  ApiMediaFormat,
  ApiOnProgress,
  ApiSessionData,
  OnApiUpdate,
} from "@messenger/api/types";

import {
  DEBUG,
  DEBUG_GRAMJS,
  UPLOAD_WORKERS,
  IS_TEST,
  APP_VERSION,
  IS_PRODUCTION,
  IS_BUSINESS_APP,
} from "@messenger/config";
import {
  onRequestPhoneNumber,
  onRequestCode,
  onRequestPassword,
  onRequestRegistration,
  onAuthError,
  onAuthReady,
  onCurrentUserUpdate,
  onRequestQrCode,
  onUpdateSendCode,
} from "@messenger/api/gramjs/methods/auth";
import { updater } from "@messenger/api/gramjs/updater";
import { setMessageBuilderCurrentUserId } from "@messenger/api/gramjs/apiBuilders/messages";
import downloadMediaWithClient from "@messenger/api/gramjs/methods/media";
import { buildApiUserFromFull } from "@messenger/api/gramjs/apiBuilders/users";
import localDb, { clearLocalDb } from "@messenger/api/gramjs/localDb";
import { buildApiPeerId } from "@messenger/api/gramjs/apiBuilders/peers";

const DEFAULT_USER_AGENT = "Unknown UserAgent";
const DEFAULT_PLATFORM = "Unknown platform";
const APP_CODE_NAME = "Z";

GramJsLogger.setLevel(DEBUG_GRAMJS ? "debug" : "warn");

const gramJsUpdateEventBuilder = { build: (update: object) => update };

let onUpdate: OnApiUpdate;
let client: TelegramClient;
let isConnected = false;

export async function init(
  _onUpdate: OnApiUpdate,
  initialArgs: ApiInitialArgs,
) {
  if (DEBUG) {
    console.log(">>> START INIT API");
  }

  onUpdate = _onUpdate;

  const {
    userAgent,
    platform,
    sessionData,
    isTest,
    isMovSupported,
    isWebmSupported,
  } = initialArgs;
  const session = new sessions.CallbackSession(sessionData, onSessionUpdate);

  console.log("session", { session });

  (self as any).isMovSupported = isMovSupported;
  (self as any).isWebmSupported = isWebmSupported;

  client = new TelegramClient(
    session,
    process.env.TELEGRAM_T_API_ID,
    process.env.TELEGRAM_T_API_HASH,
    {
      deviceModel: navigator.userAgent || userAgent || DEFAULT_USER_AGENT,
      systemVersion: platform || DEFAULT_PLATFORM,
      appVersion: `${APP_VERSION} ${APP_CODE_NAME}`,
      useWSS: true,
      additionalDcsDisabled: IS_TEST,
      testServers: isTest,
    } as any,
  );

  client.addEventHandler(handleGramJsUpdate, gramJsUpdateEventBuilder);
  client.addEventHandler(updater, gramJsUpdateEventBuilder);

  try {
    if (DEBUG) {
      console.log("[GramJs/client] CONNECTING");

      (self as any).invoke = invokeRequest;
      (self as any).GramJs = GramJs;
    }

    try {
      await client.start({
        phoneNumber: onRequestPhoneNumber,
        phoneCode: onRequestCode,
        password: onRequestPassword,
        firstAndLastNames: onRequestRegistration,
        qrCode: onRequestQrCode,
        onError: onAuthError,
        initialMethod: IS_PRODUCTION ? "qrCode" : "phoneNumber",
        isBusiness: IS_BUSINESS_APP,
        onSendCode: onUpdateSendCode,
      });
    } catch (err: any) {
      console.error(err);

      if (
        err.message !== "Disconnect" &&
        err.message !== "Cannot send requests while disconnected"
      ) {
        onUpdate({
          "@type": "updateConnectionState",
          connectionState: "connectionStateBroken",
        });

        return;
      }
    }

    if (DEBUG) {
      console.log(">>> FINISH INIT API");
      console.log("[GramJs/client] CONNECTED");
    }

    onAuthReady();
    onSessionUpdate(session.getSessionData());
    onUpdate({ "@type": "updateApiReady" });

    void fetchCurrentUser();
  } catch (err) {
    if (DEBUG) {
      console.log("[GramJs/client] CONNECTING ERROR", err);
    }

    throw err;
  }
}

export async function destroy(noLogOut = false) {
  if (!noLogOut) {
    await invokeRequest(new GramJs.auth.LogOut());
  }

  clearLocalDb();

  await client.destroy();
}

export async function disconnect() {
  await client.disconnect();
}

export function getClient() {
  return client;
}

function onSessionUpdate(sessionData: ApiSessionData) {
  onUpdate({
    "@type": "updateSession",
    sessionData,
  });
}

function handleGramJsUpdate(update: any) {
  if (update instanceof connection.UpdateConnectionState) {
    isConnected = update.state === connection.UpdateConnectionState.connected;
  } else if (update instanceof GramJs.UpdatesTooLong) {
    void handleTerminatedSession();
  } else if (update instanceof connection.UpdateServerTimeOffset) {
    onUpdate({
      "@type": "updateServerTimeOffset",
      serverTimeOffset: update.timeOffset,
    });
  }
}

export async function invokeRequest<T extends GramJs.AnyRequest>(
  request: T,
  shouldReturnTrue: true,
  shouldThrow?: boolean,
  shouldIgnoreUpdates?: undefined,
  dcId?: number,
): Promise<true | undefined>;

export async function invokeRequest<T extends GramJs.AnyRequest>(
  request: T,
  shouldReturnTrue?: boolean,
  shouldThrow?: boolean,
  shouldIgnoreUpdates?: boolean,
  dcId?: number,
): Promise<T["__response"] | undefined>;

export async function invokeRequest<T extends GramJs.AnyRequest>(
  request: T,
  shouldReturnTrue = false,
  shouldThrow = false,
  shouldIgnoreUpdates = false,
  dcId?: number,
) {
  if (!isConnected) {
    if (DEBUG) {
      console.warn(
        `[GramJs/client] INVOKE ERROR ${request.className}: Client is not connected`,
      );
    }

    return undefined;
  }

  try {
    if (DEBUG) {
      console.log(`[GramJs/client] INVOKE ${request.className}`);
    }

    const result = await client.invoke(request, dcId);

    if (DEBUG) {
      console.log(
        `[GramJs/client] INVOKE RESPONSE ${request.className}`,
        result,
      );
    }

    if (!shouldIgnoreUpdates) {
      handleUpdatesFromRequest(request, result);
    }

    return shouldReturnTrue ? result && true : result;
  } catch (err: any) {
    if (DEBUG) {
      console.log(`[GramJs/client] INVOKE ERROR ${request.className}`);
      console.error(err);
    }

    if (shouldThrow) {
      throw err;
    }

    dispatchErrorUpdate(err, request);

    return undefined;
  }
}

function handleUpdatesFromRequest<T extends GramJs.AnyRequest>(
  request: T,
  result: any,
) {
  let manyUpdates: GramJs.UpdatesCombined | GramJs.Updates | undefined;
  let singleUpdate:
    | GramJs.UpdateShortMessage
    | GramJs.UpdateShortChatMessage
    | GramJs.UpdateShort
    | GramJs.UpdateShortSentMessage
    | undefined;

  if (
    result instanceof GramJs.UpdatesCombined ||
    result instanceof GramJs.Updates
  ) {
    manyUpdates = result;
  } else if (
    typeof result === "object" &&
    "updates" in result &&
    (result.updates instanceof GramJs.Updates ||
      result.updates instanceof GramJs.UpdatesCombined)
  ) {
    manyUpdates = result.updates;
  } else if (
    result instanceof GramJs.UpdateShortMessage ||
    result instanceof GramJs.UpdateShortChatMessage ||
    result instanceof GramJs.UpdateShort ||
    result instanceof GramJs.UpdateShortSentMessage
  ) {
    singleUpdate = result;
  }

  if (manyUpdates) {
    injectUpdateEntities(manyUpdates);

    for (const update of manyUpdates.updates) {
      updater(update, request);
    }
  } else if (singleUpdate) {
    updater(singleUpdate, request);
  }
}

export function downloadMedia(
  args: {
    url: string;
    mediaFormat: ApiMediaFormat;
    start?: number;
    end?: number;
    isHtmlAllowed?: boolean;
  },
  onProgress?: ApiOnProgress,
) {
  return downloadMediaWithClient(args, client, isConnected, onProgress);
}

export function uploadFile(file: File, onProgress?: ApiOnProgress) {
  return client.uploadFile({ file, onProgress, workers: UPLOAD_WORKERS });
}

export function getMe() {
  return client.getMe();
}
export function getSessionData() {
  return client.session.getSessionData();
}
export function updateTwoFaSettings(params: TwoFaParams) {
  return client.updateTwoFaSettings(params);
}

export async function fetchCurrentUser() {
  const userFull = await invokeRequest(
    new GramJs.users.GetFullUser({
      id: new GramJs.InputUserSelf(),
    }),
  );

  if (!userFull || !(userFull.users[0] instanceof GramJs.User)) {
    return;
  }

  const user = userFull.users[0];

  if (user.photo instanceof GramJs.Photo) {
    localDb.photos[user.photo.id.toString()] = user.photo;
  }
  localDb.users[buildApiPeerId(user.id, "user")] = user;

  const currentUser = buildApiUserFromFull(userFull);

  setMessageBuilderCurrentUserId(currentUser.id);
  onCurrentUserUpdate(currentUser);
}

export function dispatchErrorUpdate<T extends GramJs.AnyRequest>(
  err: Error,
  request: T,
) {
  const isSlowMode =
    err.message.startsWith("A wait of") &&
    (request instanceof GramJs.messages.SendMessage ||
      request instanceof GramJs.messages.SendMedia ||
      request instanceof GramJs.messages.SendMultiMedia);

  const { message } = err;

  onUpdate({
    "@type": "error",
    error: {
      message,
      isSlowMode,
      hasErrorKey: true,
    },
  });
}

function injectUpdateEntities(result: GramJs.Updates | GramJs.UpdatesCombined) {
  const entities = [...result.users, ...result.chats];

  for (const update of result.updates) {
    if (entities) {
      (update as any)._entities = entities;
    }
  }
}

async function handleTerminatedSession() {
  try {
    await invokeRequest(
      new GramJs.users.GetFullUser({
        id: new GramJs.InputUserSelf(),
      }),
      undefined,
      true,
    );
  } catch (err: any) {
    if (err.message === "AUTH_KEY_UNREGISTERED") {
      onUpdate({
        "@type": "updateConnectionState",
        connectionState: "connectionStateBroken",
      });
    }
  }
}
