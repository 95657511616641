import { Api as GramJs } from '../../lib/gramjs';

export type LangPackLanguage = {
  CONSTRUCTOR_ID: number;
  SUBCLASS_OF_ID: number;
  className: string;
  classType: 'constructor' | 'request';
  flags: number;
  official?: true;
  rtl?: boolean;
  beta?: boolean;
  name: string;
  nativeName: string;
  langCode: string;
  baseLangCode?: string;
  pluralCode: string;
  stringsCount: number;
  translatedCount: number;
  translationsUrl: string;
};

export const LangpacksPrebuilt: LangPackLanguage[] = [
  {
    CONSTRUCTOR_ID: 4006239459,
    SUBCLASS_OF_ID: 2880211383,
    className: 'LangPackLanguage',
    classType: 'constructor',
    flags: 1,
    official: true,
    rtl: false,
    beta: false,
    name: 'English',
    nativeName: 'English',
    langCode: 'en',
    pluralCode: 'en',
    stringsCount: 4793,
    translatedCount: 4793,
    translationsUrl: 'https://tawasal.ae',
  },
  {
    CONSTRUCTOR_ID: 4006239459,
    SUBCLASS_OF_ID: 2880211383,
    className: 'LangPackLanguage',
    classType: 'constructor',
    flags: 5,
    official: true,
    rtl: true,
    beta: false,
    name: 'Arabic',
    nativeName: 'العربية',
    langCode: 'ar',
    pluralCode: 'ar',
    stringsCount: 4793,
    translatedCount: 4780,
    translationsUrl: 'https://tawasal.ae',
  },
  {
    CONSTRUCTOR_ID: 4006239459,
    SUBCLASS_OF_ID: 2880211383,
    className: 'LangPackLanguage',
    classType: 'constructor',
    flags: 1,
    official: true,
    rtl: false,
    beta: false,
    name: 'French',
    nativeName: 'Français',
    langCode: 'fr',
    pluralCode: 'fr',
    stringsCount: 4793,
    translatedCount: 4771,
    translationsUrl: 'https://tawasal.ae',
  },
  {
    CONSTRUCTOR_ID: 4006239459,
    SUBCLASS_OF_ID: 2880211383,
    className: 'LangPackLanguage',
    classType: 'constructor',
    flags: 1,
    official: true,
    rtl: false,
    beta: false,
    name: 'Russian',
    nativeName: 'Русский',
    langCode: 'ru',
    pluralCode: 'ru',
    stringsCount: 4793,
    translatedCount: 4790,
    translationsUrl: 'https://tawasal.ae',
  },
  {
    CONSTRUCTOR_ID: 4006239459,
    SUBCLASS_OF_ID: 2880211383,
    className: 'LangPackLanguage',
    classType: 'constructor',
    flags: 5,
    official: true,
    rtl: true,
    beta: false,
    name: 'Persian',
    nativeName: 'فارسی',
    langCode: 'fa',
    pluralCode: 'fa',
    stringsCount: 4793,
    translatedCount: 4771,
    translationsUrl: 'https://tawasal.ae',
  },
  {
    CONSTRUCTOR_ID: 4006239459,
    SUBCLASS_OF_ID: 2880211383,
    className: 'LangPackLanguage',
    classType: 'constructor',
    flags: 1,
    official: true,
    rtl: false,
    beta: false,
    name: 'Spanish',
    nativeName: 'Español',
    langCode: 'es',
    pluralCode: 'es',
    stringsCount: 4793,
    translatedCount: 4791,
    translationsUrl: 'https://tawasal.ae',
  },
  {
    CONSTRUCTOR_ID: 4006239459,
    SUBCLASS_OF_ID: 2880211383,
    className: 'LangPackLanguage',
    classType: 'constructor',
    flags: 1,
    official: true,
    rtl: false,
    beta: false,
    name: 'Turkish',
    nativeName: 'Türkçe',
    langCode: 'tr',
    pluralCode: 'tr',
    stringsCount: 4793,
    translatedCount: 4767,
    translationsUrl: 'https://tawasal.ae',
  },
];
