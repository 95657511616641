import React, { type FC, memo, useState } from "@messenger/lib/teact/teact";

import { getActions, withGlobal } from "@messenger/global";

import type { GlobalState } from "@messenger/global/types";
import { pick } from "@messenger/util/iteratees";
import BusinessAuthHeader from "./BusinessAuthHeader";
import { getInitials } from "./helpers/getInitials";
import useI18n from "@@/src/app/hooks/useI18n";

type StateProps = Pick<GlobalState, "companyForm" | "ownerAuthForm">;

const CompanyCreated: FC<StateProps> = ({ companyForm, ownerAuthForm }) => {
  const { dictionary } = useI18n(["business"]);
  const { setBusinessAuthState } = getActions();

  const handleSubmit = () => {
    setBusinessAuthState({ state: "businessAuthStateReady" });
  };

  return (
    <div className="flex flex-col items-center w-full">
      <BusinessAuthHeader
        title={`${dictionary.business.signIn.welcome} ${companyForm?.title || ""}`}
      />
      <div className="flex flex-col gap-2 w-full max-w-sm">
        <div className="flex w-full border border-gray-300 rounded-xl p-2">
          <div className="h-14 w-14 rounded-full bg-gray-300 flex justify-center items-center text-white text-xl mr-3">
            {getInitials(ownerAuthForm?.firstName, ownerAuthForm?.lastName)}
          </div>
          <div className="flex flex-col justify-center">
            <h2 className="text-start text-black text-lg mb-0">
              {ownerAuthForm?.firstName} {ownerAuthForm?.lastName}
            </h2>
            <span className="text-start text-gray-700/60 text-sm">
              @{ownerAuthForm?.username}
            </span>
          </div>
        </div>
        <div className="flex flex-col items-center mt-8 gap-1">
          <div className="h-20 w-20 rounded-full bg-gray-300 flex justify-center items-center text-white text-2xl">
            {getInitials(companyForm?.title)}
          </div>
          <h2 className="text-center text-black text-lg mb-0">
            {companyForm?.title}
          </h2>
          <span className="text-center text-gray-700/60 text-sm">
            @{companyForm?.shortName}
          </span>
        </div>
      </div>
      <button
        onClick={handleSubmit}
        className="mt-auto h-11 w-full max-w-sm bg-black rounded-xl text-[#37CB37] cursor-pointer"
      >
        {dictionary.business.signIn.start}
      </button>
    </div>
  );
};

export default memo(
  withGlobal(
    (global): StateProps => pick(global, ["companyForm", "ownerAuthForm"]),
  )(CompanyCreated),
);
