import React, { type FC, useEffect, memo } from "@messenger/lib/teact/teact";
import { getActions, withGlobal } from "@messenger/global";

import type { GlobalState } from "@messenger/global/types";

import "@messenger/global/actions/initial";
import "@messenger/global/actions/api/business";
import { pick } from "@messenger/util/iteratees";
import { PLATFORM_ENV } from "@messenger/util/environment";
import windowSize from "@messenger/util/windowSize";
import logo from "@messenger/assets/business/logo.svg";

import CreateAccount from "./CreateAccount";
import CompanyName from "./CompanyName";
import CompanyPhoto from "./CompanyPhoto";

import LoadingSpinner from "@messenger/components/ui/Loading";
import LanguageSelector from "@messenger/components/language-selector";
import CompanyCreated from "./CompanyCreated";
import SignUpEmployer from "./SignUpEmployer";
import SignIn from "./SignIn";
import SendCode from "./SendCode";
import Register from "./Register";



type OwnProps = {
  isActive: boolean;
};

type StateProps = Pick<GlobalState, "businessAuthState" | "connectionState" | "authState">;

const BusinessAuth: FC<OwnProps & StateProps> = ({
  businessAuthState,
  authState,
  connectionState,
  isActive,
}) => {
  const { initBusiness } = getActions();

  const companyInvite = new URLSearchParams(window.location.search).get(
    "companyInvite",
  );

  const { reset, initApi, returnToAuthPhoneNumber, goToAuthQrCode } =
  getActions();

useEffect(() => {
  if (isActive) {
    reset();
    initApi();
  }
}, [isActive, reset, initApi]);

  useEffect(() => {
    if (connectionState === "connectionStateReady") {
      initBusiness({ companyInvite });
    }
  }, [initBusiness, connectionState]);

  // Prevent refresh when rotating device
  useEffect(() => {
    windowSize.disableRefresh();

    return () => {
      windowSize.enableRefresh();
    };
  }, []);

  const renderContent = () => {
    switch (businessAuthState) {
      case "businessAuthStateSignIn":
        if (authState === "authorizationStateWaitCode") {
          return <SendCode />;
        }
        if (authState === "authorizationStateWaitRegistration") {
          return <Register />
        }
        return <SignIn />
      case "businessAuthStateAccountInfo":
        return <CreateAccount />;
      case "businessAuthStateCompanyName":
        return <CompanyName />;
      case "businessAuthStateCompanyPhoto":
        return <CompanyPhoto />;
      case "businessAuthStateCompanyCreated":
        return <CompanyCreated />;
      case "businessAuthStateSignUpEmployer":
        return <SignUpEmployer />;
      default:
        return <Loading />;
    }
  };

  return (
    <div className="h-full w-full bg-[#37CB37] flex justify-center items-center relative">
      <div>
        <LanguageSelector />
      </div>
      <div className="w-4/5 h-4/5 bg-center bg-no-repeat bg-contain flex flex-col justify-center items-center bg-[url('@messenger/assets/business/auth-bg.png')]">
        {/* <div className="mb-2 flex items-center">
          <img src={logo} />
          <h1 className="text-xl font-semibold">TAWASAL BUSINESS</h1>
        </div> */}
        <div className="w-[400px] h-full bg-white rounded-2xl p-9 flex">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default memo(
  withGlobal<OwnProps>(
    (global): StateProps =>
      pick(global, ["businessAuthState", "connectionState", "authState"]),
  )(BusinessAuth),
);

const Loading = () => {
  return (
    <div className="flex w-full h-full justify-center items-center">
      <LoadingSpinner color="black" />
    </div>
  );
};
