import React, { type FC, memo, useState } from "@messenger/lib/teact/teact";

import { getActions, withGlobal } from "@messenger/global";

import type { GlobalState } from "@messenger/global/types";
import { pick } from "@messenger/util/iteratees";

import BusinessAuthHeader from "./BusinessAuthHeader";

import AvatarEdit from "./AvatarEdit";

type StateProps = Pick<GlobalState, "companyForm">;

const CompanyPhoto: FC<StateProps> = ({ companyForm }) => {
  const { updateCompanyPhoto, setBusinessAuthState } = getActions();

  const [photo, setPhoto] = useState<File | undefined>();

  const handleSubmit = () => {
    if (photo) {
      updateCompanyPhoto({ photo });
    } else {
      handleSkip();
    }
  };

  const handleSkip = () => {
    setBusinessAuthState({
      state: "businessAuthStateCompanyCreated",
    });
  };
  return (
    <div className="flex flex-col items-center w-full">
      <BusinessAuthHeader
        title="Want to add a company photo?"
        withSubtitle={false}
      />
      <div className="flex flex-col gap-2 w-full max-w-sm items-center">
        <AvatarEdit
          form={{
            firstName: companyForm?.title,
          }}
          setSelectedFile={setPhoto}
        />
      </div>
      <h2 className="text-start text-black text-lg mb-0">
        {companyForm?.title}
      </h2>
      <span className="text-start text-gray-700/60 text-sm">
        @{companyForm?.shortName}
      </span>
      <button
        onClick={handleSubmit}
        className="mt-auto h-11 w-full max-w-sm bg-black rounded-xl text-[#37CB37] cursor-pointer"
      >
        Submit
      </button>
      <button
        onClick={handleSkip}
        className="mt-2 h-11 max-w-sm bg-transparent text-[#37CB37] cursor-pointer"
      >
        Skip
      </button>
    </div>
  );
};

export default memo(
  withGlobal((global): StateProps => pick(global, ["companyForm"]))(
    CompanyPhoto,
  ),
);
