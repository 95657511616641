import { addActionHandler } from "@messenger/global";
import { callApi } from "@messenger/api/gramjs";
import { Api } from "@messenger/lib/gramjs";
import { storeSession } from "@@/src/app/util/sessions";

addActionHandler("checkCompanyUsername", async (global, actions, payload) => {
  const { shortName } = payload;

  const result = await callApi("checkCompanyUsername", {
    shortName,
  });

  actions.setBusinessAuthState({
    state: "businessAuthStateCompanyName",
    ownerAuthForm: {
      ...global.ownerAuthForm,
      isChecked: !!result,
    },
  });
});

addActionHandler("storeEmployerSession", async (global, actions, payload) => {  
  const isAuthenticated = await callApi("checkAuth");
  const self = await callApi("getSelfUser");
  const sessionData = await callApi("getSelfSessionData");
  if (isAuthenticated && self) {
    storeSession(sessionData, String(self.id))
    return ({
      ...global,
      authState: "authorizationStateReady",
    })
  }
})

addActionHandler("createBusinessAccount", async (global, actions, payload) => {
  const { form } = payload;

  const result = await callApi("createBusinessAccount", { form });
  if (result) {
    const user = (result as Api.auth.Authorization).user as any; // scheme issue
    actions.setBusinessAuthState({
      ownerAuthForm: {
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
      },
      state: "businessAuthStateCompanyName",
    });
  }
});

addActionHandler("createCompany", async (global, actions, payload) => {
  const { form } = payload;
  actions.setBusinessAuthState({ state: undefined }); //TODO: create loading state

  const check = await callApi("checkCompanyUsername", {
    shortName: form.shortName,
  });

  if (check === undefined) {
    actions.setBusinessAuthState({
      ownerAuthForm: {
        ...global.ownerAuthForm,
        isChecked: false,
      },
    });
  } else {
    const result = await callApi("createCompany", { form });
    if (result) {
      actions.setBusinessAuthState({
        state: "businessAuthStateCompanyCreated", //TODO: change to businessAuthStateCompanyPhoto when api is ready
        companyForm: form,
      });
    } else {
      actions.setBusinessAuthState({ state: "businessAuthStateCompanyName" });
    }
  }
});

addActionHandler("setBusinessAuthState", (global, actions, payload) => {
  const { state, ownerAuthForm, companyForm } = payload;
  const { ownerAuthForm: prevOwnerAuthForm, companyForm: prevCompanyForm } =
    global;
  return {
    ...global,
    businessAuthState: state,
    companyForm: companyForm ?? prevCompanyForm,
    ownerAuthForm: ownerAuthForm ?? prevOwnerAuthForm,
  };
});

addActionHandler("exportCompanyInvite", async (global, actions, payload) => {
  const { company } = payload;

  const result = await callApi("exportCompanyInvite", { company });

  if (result) {
    const urlParts = (result as Api.ExportedCompanySignUpInvite)?.link.split(
      "/",
    );
    let hash = urlParts[urlParts.length - 1];
    let formattedLink = `${window.location.origin}/business/joincompany/${hash}`;
    await navigator.clipboard.writeText(formattedLink);
    actions.showNotification({ message: "Link copied to clipboard123" });
  }
});

addActionHandler("updateCompanyPhoto", async (global, actions, payload) => {
  const { photo } = payload;

  const result = await callApi("updateCompanyPhoto", { photo });

  if (result) {
    actions.setBusinessAuthState({
      state: "businessAuthStateCompanyCreated",
    });
  }
});

addActionHandler("checkCompanyInvite", async (global, actions, payload) => {
  const { companyInvite } = payload;
  const result = await callApi("checkCompanyInvite", { invite: companyInvite });
});

addActionHandler("getCompany", async (global, actions, payload) => {
  const { company } = payload;
  const result = await callApi("getCompany", { company });
  if (result) {
    return {
      ...global,
      company: result,
    };
  }
});
