import React, { type FC, useEffect } from "@messenger/lib/teact/teact";
import { getActions, withGlobal } from "@messenger/global";

import type { GlobalState } from "@messenger/global/types";
import type { UiLoaderPage } from "@messenger/components/common/UiLoader";

import { INACTIVE_MARKER, PAGE_TITLE } from "@messenger/config";
import { PLATFORM_ENV } from "@messenger/util/environment";
import { updateSizes } from "@messenger/util/windowSize";
import { addActiveTabChangeListener } from "@messenger/util/activeTabMonitor";
import { hasStoredSession } from "@messenger/util/sessions";
import buildClassName from "@messenger/util/buildClassName";
import useFlag from "@messenger/hooks/useFlag";
import usePrevious from "@messenger/hooks/usePrevious";

import Auth from "@messenger/components/auth/Auth";
import Main from "@messenger/components/main/Main.async";
import LockScreen from "@messenger/components/main/LockScreen.async";
import AppInactive from "@messenger/components/main/AppInactive";
import Transition from "@messenger/components/ui/Transition";
import UiLoader from "@messenger/components/common/UiLoader";
import BusinessAuth from "@messenger/components/businessAuth/BusinessAuth";
// import Test from './components/test/TestNoRedundancy';

type StateProps = {
  authState: GlobalState["authState"];
  businessAuthState: GlobalState["businessAuthState"];
  isScreenLocked?: boolean;
  isBusinessApp: boolean;
};

enum AppScreens {
  auth = 0,
  lock = 1,
  main = 2,
  inactive = 3,
  businessAuth = 4,
}

const App: FC<StateProps> = ({
  authState,
  isScreenLocked,
  businessAuthState,
  isBusinessApp,
}) => {
  const { disconnect, setSignUpEmployerFlow } = getActions();

  const [isInactive, markInactive] = useFlag(false);
  const isMobile = PLATFORM_ENV === "iOS" || PLATFORM_ENV === "Android";

  useEffect(() => {
    updateSizes();
    addActiveTabChangeListener(() => {
      disconnect();
      document.title = `${PAGE_TITLE}${INACTIVE_MARKER}`;

      markInactive();
    });
  }, [disconnect, markInactive]);

  useEffect(() => {
    if (window.location.search.includes('companyInvite') && setSignUpEmployerFlow) {
      setSignUpEmployerFlow();
    }
  }, [disconnect])

  // return <Test />;

  let activeKey: number;
  let page: UiLoaderPage | undefined;

  if (isInactive) {
    activeKey = AppScreens.inactive;
  } else if (isBusinessApp && authState !== "authorizationStateReady") {
    activeKey = AppScreens.businessAuth;
  } else if (isScreenLocked) {
    page = "lock";
    activeKey = AppScreens.lock;
  } else if (authState) {
    switch (authState) {
      case "authorizationStateWaitPhoneNumber":
        page = "authPhoneNumber";
        activeKey = AppScreens.auth;
        break;
      case "authorizationStateWaitCode":
        page = "authCode";
        activeKey = AppScreens.auth;
        break;
      case "authorizationStateWaitPassword":
        page = "authPassword";
        activeKey = AppScreens.auth;
        break;
      case "authorizationStateWaitRegistration":
        activeKey = AppScreens.auth;
        break;
      case "authorizationStateWaitQrCode":
        page = "authQrCode";
        activeKey = AppScreens.auth;
        break;
      case "authorizationStateClosed":
      case "authorizationStateClosing":
      case "authorizationStateLoggingOut":
      case "authorizationStateReady":
        page = "main";
        activeKey = AppScreens.main;
        break;
    }
  } else if (hasStoredSession(true)) {
    page = "main";
    activeKey = AppScreens.main;
  } else {
    page = isMobile ? "authPhoneNumber" : "authQrCode";
    activeKey = AppScreens.auth;
  }

  const prevActiveKey = usePrevious(activeKey);

  function renderContent(isActive: boolean) {
    switch (activeKey) {
      case AppScreens.auth:
        return <Auth isActive={isActive} />;
      case AppScreens.main:
        return <Main />;
      case AppScreens.lock:
        return <LockScreen isLocked={isScreenLocked} />;
      case AppScreens.inactive:
        return <AppInactive />;
      case AppScreens.businessAuth:
        return <BusinessAuth isActive={isActive} />;
    }
  }

  return (
    <UiLoader key="Loader" page={page}>
      <Transition
        name="fade"
        activeKey={activeKey}
        shouldCleanup
        className={buildClassName(
          "full-height",
          (activeKey === AppScreens.auth ||
            prevActiveKey === AppScreens.auth) &&
            "is-auth",
        )}
      >
        {renderContent}
      </Transition>
    </UiLoader>
  );
};

export default withGlobal((global): StateProps => {
  return {
    authState: global.authState,
    isScreenLocked: global.passcode?.isScreenLocked,
    businessAuthState: global.businessAuthState,
    isBusinessApp: global.isBusinessApp,
  };
})(App);
