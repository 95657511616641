import React from "@messenger/lib/teact/teact";
import { LangpacksPrebuilt } from "@messenger/util/__mocks__/twlLangpacks";
import useI18n from "@@/src/app/hooks/useI18n";

const LanguageSelect = () => {
  const { language, setLanguage } = useI18n(["business"]);

  return (
    <label className="absolute right-4 top-4 flex items-center justify-center gap-2 rounded-xl bg-[#591C0082] p-2">
      <select
        onChange={(event) => {
          setLanguage(event.target.value as "en");
        }}
        className="absolute h-full w-full bg-transparent opacity-0"
        id="language"
      >
        {LangpacksPrebuilt.map((thisLang) => (
          <option
            key={thisLang.langCode}
            value={thisLang.langCode}
            selected={language === thisLang.langCode}
          >
            {thisLang.name}
          </option>
        ))}
      </select>
      <span className="text-white">{language?.toUpperCase() ?? "EN"}</span>
    </label>
  );
};

export default LanguageSelect;
