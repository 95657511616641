import React, {
  type FC,
  memo,
  useEffect,
  useState,
  useRef,
} from "@messenger/lib/teact/teact";

import BusinessAuthHeader from "./BusinessAuthHeader";
import useI18n from "@messenger/hooks/useI18n";
import { getActions, withGlobal } from "@messenger/global";
import QrCreator from "qr-creator";
import type { GlobalState } from "@messenger/global/types";
import { cn } from "@@/src/app/shared/styles";

type StateProps = Pick<
  GlobalState,
  "connectionState" | "authState" | "authQrCode" | "authError"
>;

const DATA_PREFIX = "twl://login?token=";

const SignIn: FC<StateProps> = ({ connectionState, authQrCode, authError }) => {
  const { dictionary } = useI18n(["business"]);

  const { setAuthPhoneNumber, clearAuthError } = getActions();
  const [phoneNumber, setPhoneNumber] = useState("");

  const qrCodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!authQrCode || connectionState !== "connectionStateReady") return;

    const container = qrCodeRef.current;
    if (!container) return;

    container.innerHTML = "";
    container.classList.remove("pre-animate");

    QrCreator.render(
      {
        text: `${DATA_PREFIX}${authQrCode.token}`,
        radius: 0.5,
        ecLevel: "H",
        size: 280 * 4,
        top: 10,
        fill: "black",
      },
      container,
    );
  }, [connectionState, authQrCode]);

  const handlePhoneNumberChange = (value: string) => {
    if (authError) {
      clearAuthError();
    }
    setPhoneNumber(value);
  };

  const handleSubmit = async () => {
    setAuthPhoneNumber({ phoneNumber });
  };

  return (
    <div className="flex flex-col items-center w-full h-full">
      <BusinessAuthHeader title={dictionary.business.signIn.welcomeTawasal} />
      <div className="flex flex-col gap-2 w-full max-w-sm h-full">
        <input
          className={cn(
            "h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]",
            authError && "border-red-500",
          )}
          placeholder={dictionary.business.signIn.phoneNumber}
          value={phoneNumber}
          inputMode="tel"
          onChange={(e) => handlePhoneNumberChange(e.target.value)}
        />
        {authError && (
          <span className="text-red-500 text-left text-xs pl-4">
            {authError}
          </span>
        )}
        <button
          onClick={handleSubmit}
          className="mt-4 h-11 w-full max-w-sm bg-black rounded-xl text-[#37CB37] cursor-pointer"
        >
          {dictionary.business.signIn.buttonSign}
        </button>
        <div className="flex justify-center items-center gap-4">
          <div className="h-[1px] w-full bg-[#E7E9EA]" />
          <span className="text-[#B0B3B5] text-xs">OR</span>
          <div className="h-[1px] w-full bg-[#E7E9EA]" />
        </div>
        {authQrCode ? (
          <div className="w-full bg-[#F5F7F8] rounded-2xl flex flex-col items-center">
            <div
              key="qr-container"
              className={cn(
                // styles.qrContainer,
                "pre-animate p-2 pb-0 flex justify-center items-center [&_canvas]:w-[150px] [&_canvas]:h-[150px]",
              )}
              ref={qrCodeRef}
            />
            <div className="py-4 flex flex-col gap-1 items-center">
              <div className="w-6 h-6 rounded-full bg-[#DEE0E1] flex items-center justify-center text-sm font-semibold">
                1
              </div>
              <span className="text-sm text-center">
                {dictionary.business.signIn.qr.first}
              </span>
              <div className="w-6 h-6 rounded-full bg-[#DEE0E1] flex items-center justify-center text-sm font-semibold">
                2
              </div>
              <span className="text-sm text-center">
                {dictionary.business.signIn.qr.second}
              </span>
              <div className="w-6 h-6 rounded-full bg-[#DEE0E1] flex items-center justify-center text-sm font-semibold">
                3
              </div>
              <span className="text-sm text-center">
                {dictionary.business.signIn.qr.third}
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const {
      connectionState,
      authState,
      authQrCode,
      authError,
      settings: {
        byKey: { language },
      },
    } = global;

    return {
      connectionState,
      authState,
      authQrCode,
      authError,
    };
  })(SignIn),
);
