import React, {
  FC,
  memo,
  useEffect,
  useRef,
  useState,
} from "@messenger/lib/teact/teact";
import { ChangeEvent, FocusEvent, KeyboardEvent, ClipboardEvent } from "react";
import useI18n from "@messenger/hooks/useI18n";
import { getActions, withGlobal } from "@messenger/global";
import type { GlobalState } from "@messenger/global/types";
import { cn } from "@messenger/shared/styles";
import ConfirmDialog from "@messenger/components/ui/ConfirmDialog";

type StateProps = Pick<
  GlobalState,
  "authState" | "authError" | "authPhoneNumber" | "sendCodeResult"
>;

const CODE_LENGTH = 5;

const SendCode: FC<StateProps> = ({
  authError,
  authPhoneNumber,
  sendCodeResult,
}) => {
  const { dictionary } = useI18n(["business"]);
  const { setAuthCode, resendCode, goBack } = getActions();

  const [timeLeft, setTimeLeft] = useState<number | undefined>(undefined);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const [isBackConfirmDialogOpen, setBackConfirmDialogOpen] = useState(false);

  const [code, setCode] = useState<string>("");

  const inputRefs = Array.from({ length: CODE_LENGTH }, () =>
    useRef<HTMLInputElement>(null),
  );

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const resetCode = () => {
    inputRefs.forEach((ref) => {
      if (ref.current) ref.current.value = "";
    });
    inputRefs[0].current?.focus();
    setCode("");
  };

  useEffect(() => {
    if (code.length === CODE_LENGTH) {
      setAuthCode({ code });
      resetCode();
    }
  }, [code, setAuthCode]);

  useEffect(() => {
    if (sendCodeResult?.timeout) {
      setTimeLeft(sendCodeResult.timeout);
    }
  }, [sendCodeResult]);

  useEffect(() => {
    if (timeLeft === 0) {
      setIsResendVisible(Boolean(sendCodeResult?.nextType));
      if (sendCodeResult?.nextType === undefined) {
        setIsHelpVisible(true);
      }
      return;
    }

    const timerInterval = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime && prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timeLeft]);

  useEffect(() => {
    resetCode();
  }, []);

  const handleInput = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const input = e.target;
    const previousInput = inputRefs[index - 1]?.current;
    const nextInput = inputRefs[index + 1]?.current;

    const updatedCode = [...code];
    const value = input.value;

    if (/^\d$/.test(value)) {
      updatedCode[index] = value;
      setCode(updatedCode.join(""));
    }

    if (value === "") {
      previousInput?.focus();
    } else {
      nextInput?.focus();
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    const input = e.target as HTMLInputElement;
    const previousInput = inputRefs[index - 1]?.current;

    if ((e.key === "Backspace" || e.key === "Delete") && input.value === "") {
      e.preventDefault();
      const updatedCode = code.slice(0, index) + code.slice(index + 1);
      setCode(updatedCode);
      previousInput?.focus();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pastedCode = e.clipboardData.getData("text").replace(/\D/g, "");
    if (pastedCode.length === CODE_LENGTH) {
      setCode(pastedCode);
      inputRefs.forEach((ref, idx) => {
        if (ref.current) ref.current.value = pastedCode[idx] || "";
      });
    }
  };

  const handleResend = () => {
    setIsResendVisible(false);
    resendCode({ phoneNumber: authPhoneNumber });
  };

  const handleBack = () => {
    goBack({ state: "authorizationStateWaitPhoneNumber" });
  };

  return (
    <div className="flex flex-col items-center w-full h-full">
      <button
        onClick={() => setBackConfirmDialogOpen(true)}
        className="flex text-lg text-[#28CE48] items-center self-start mb-4"
      >
        <i className="icon-arrow-left text-2xl mr-1" />
        {dictionary.business.sendCode.back}
      </button>
      <h1 className="text-2xl font-semibold">
        {dictionary.business.sendCode.title}
      </h1>
      <p className="text-[#3C3C4399] font-normal text-sm text-center">
        {dictionary.business.sendCode.subtitleFirst}{" "}
        <span className="font-semibold">+{authPhoneNumber}</span>.{" "}
        {dictionary.business.sendCode.subtitleSecond}
      </p>
      <div className="flex justify-center gap-2 mt-4">
        {Array.from({ length: CODE_LENGTH }, (_, index) => (
          <input
            key={index}
            type="text"
            maxLength={1}
            onChange={(e) => handleInput(e, index)}
            ref={inputRefs[index]}
            autoFocus={index === 0}
            onFocus={handleFocus}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={handlePaste}
            className={cn(
              "rounded-2xl text-xl font-semibold h-11 w-10 text-center text-black border border-gray-300 focus:outline-none focus:border-[#37CB37]",
              authError && "border-red-500 focus:border-red-500",
            )}
          />
        ))}
      </div>
      <span className="text-[#3C3C4399] text-sm text-center font-medium mt-4">
        {!isHelpVisible
          ? dictionary.business.sendCode.resendTitle
          : dictionary.business.sendCode.helpTitle}
      </span>
      {isHelpVisible && (
        <button
          className="text-[#28CE48] text-sm text-center font-normal"
          // onClick={handleResend}
        >
          {dictionary.business.sendCode.contactSupport}
        </button>
      )}
      {isResendVisible ? (
        <button
          className="text-[#28CE48] text-sm text-center font-normal"
          onClick={handleResend}
        >
          {dictionary.business.sendCode.resend}
        </button>
      ) : !isHelpVisible ? (
        <span className="text-[#3C3C4399] text-sm text-center font-normal">
          {dictionary.business.sendCode.wait}{" "}
          <span className="text-[#28CE48]">{formatTime(timeLeft ?? 0)}</span>
        </span>
      ) : null}
      {authError && (
        <span className="text-red-500 text-left text-xs pl-4 mt-2">
          {dictionary.business.errors.unexpected}
        </span>
      )}
      <ConfirmDialog
        isOpen={isBackConfirmDialogOpen}
        onClose={() => setBackConfirmDialogOpen(false)}
        text={dictionary.business.sendCode.backConfirmTitle}
        confirmLabel={dictionary.business.sendCode.backConfirm}
        confirmHandler={handleBack}
        confirmIsDestructive
      />
    </div>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const { authState, authError, authPhoneNumber, sendCodeResult } = global;
    return {
      authState,
      authError,
      authPhoneNumber,
      sendCodeResult,
    };
  })(SendCode),
);
