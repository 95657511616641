import React, {
  type FC,
  memo,
  useCallback,
  useState,
} from "@messenger/lib/teact/teact";

import { getActions, withGlobal } from "@messenger/global";

import type { GlobalState } from "@messenger/global/types";
import { pick } from "@messenger/util/iteratees";

import BusinessAuthHeader from "./BusinessAuthHeader";

import { getInitials } from "@messenger/components/businessAuth/helpers/getInitials";
import useI18n from "@messenger/hooks/useI18n";
import { isUsernameValid } from "@messenger/util/username";
import { debounce } from "@messenger/util/schedulers";
import { callApi } from "@messenger/api/gramjs";
import { cn } from "@@/src/app/shared/styles";

type StateProps = Pick<GlobalState, "ownerAuthForm" | "company">;

type CompanyNameForm = {
  title: string;
  shortName: string;
};

const CompanyName: FC<StateProps & { isFromEdit: boolean }> = ({
  ownerAuthForm,
  isFromEdit,
  company,
}) => {
  const { createCompany, setBusinessAuthState } = getActions();
  const { dictionary } = useI18n(["business"]);
  const [usernameError, setUsernameError] = useState("");
  const [displayNameError, setDisplayNameError] = useState("");
  const [form, setForm] = useState<CompanyNameForm>({
    title: company?.title ?? "",
    shortName: company?.shortName ?? "",
  });

  const checkName = useCallback(
    debounce(async (value: string) => {
      const check = await callApi("checkCompanyUsername", {
        shortName: value,
      });
      if (check === undefined) {
        setBusinessAuthState({
          state: "businessAuthStateCompanyName",
          ownerAuthForm: {
            ...ownerAuthForm,
            isChecked: false,
          },
        });
      } else if (check === true) {
        setBusinessAuthState({
          state: "businessAuthStateCompanyName",
          ownerAuthForm: {
            ...ownerAuthForm,
            isChecked: true,
          },
        });
      }
    }, 300),
    [ownerAuthForm],
  );

  const handleFormChange = (value: string, id: keyof CompanyNameForm) => {
    if (id === "shortName") {
      validateUsername(value);
      checkName(value);
    } else if (id === "title") {
      validateDisplayName(value);
    }
    setForm({
      ...form,
      [id]: value,
    });
  };

  const validateDisplayName = (value: string) => {
    if (value.length === 0) {
      setDisplayNameError(dictionary.business.errors.firstName);
      return false;
    } else {
      setDisplayNameError("");
      return true;
    }
  };

  const validateUsername = (value: string) => {
    if (value.length === 0) {
      setUsernameError(dictionary.business.errors.emptyUsername);
      return false;
    } else if (!isUsernameValid(value, true)) {
      setUsernameError(dictionary.business.errors.username);
      return false;
    } else {
      setUsernameError("");
      return true;
    }
  };

  const handleSubmit = () => {
    if (displayNameError || usernameError) return;
    if (
      validateUsername(form.shortName ?? "") &&
      validateDisplayName(form.title ?? "")
    ) {
      if (isFromEdit) {
        //TODO edit
      } else {
        createCompany({ form });
      }
    }
  };

  return (
    <div
      className={cn("flex flex-col items-center w-full", {
        "p-4 h-52": isFromEdit,
      })}
    >
      {!isFromEdit && (
        <BusinessAuthHeader title={dictionary.business.signIn.title} />
      )}
      <div className="flex flex-col gap-2 w-full max-w-sm">
        {!isFromEdit && (
          <div className="flex w-full border border-gray-300 rounded-xl p-2">
            <div className="h-14 w-14 rounded-full bg-gray-300 flex justify-center items-center text-white text-xl mr-3">
              {getInitials(ownerAuthForm?.firstName, ownerAuthForm?.lastName)}
            </div>
            <div className="flex flex-col justify-center">
              <h2 className="text-start text-black text-lg mb-0">
                {ownerAuthForm?.firstName} {ownerAuthForm?.lastName}
              </h2>
              <span className="text-start text-gray-700/60 text-sm">
                @{ownerAuthForm?.username}
              </span>
            </div>
          </div>
        )}

        <input
          value={form["title"]}
          className="h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]"
          placeholder={dictionary.business.signIn.displayName}
          onChange={(e) => handleFormChange(e.target.value, "title")}
        />
        {usernameError && (
          <span className="text-red-500 text-left text-xs pl-4">
            {displayNameError}
          </span>
        )}
        <input
          value={form["shortName"]}
          className="h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]"
          placeholder={dictionary.business.signIn.companyUniqueName}
          onChange={(e) => handleFormChange(e.target.value, "shortName")}
        />
        {usernameError && (
          <span className="text-red-500 text-left text-xs pl-4">
            {usernameError}
          </span>
        )}
        {ownerAuthForm?.isChecked === false && (
          <span className="text-red-500 text-left text-xs pl-4">
            {dictionary.business.errors.companyNameOccupied}
          </span>
        )}
      </div>
      <button
        disabled={isFromEdit}
        onClick={handleSubmit}
        className={cn(
          "mt-auto h-11 w-full max-w-sm rounded-xl text-[#37CB37] cursor-pointer",
          {
            "bg-white, border border-black": isFromEdit,
            "bg-black": !isFromEdit,
          },
        )}
      >
        {isFromEdit
          ? dictionary.business.settings.save
          : dictionary.business.signIn.create}
      </button>
    </div>
  );
};

export default memo(
  withGlobal(
    (global): StateProps => pick(global, ["ownerAuthForm", "company"]),
  )(CompanyName),
);
